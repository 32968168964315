/**
 * External dependencies:
 */
import EventEmitter from "events";

/**
 * Internal dependencies:
 */
import { persist, fetch as fetchCart } from "./ajax";

export const eventEmitter = new EventEmitter();

class Api {
    #storage;
    #state;
    #snapshot;
    #isInit;

    constructor({ storage }) {
        this.#storage = storage();
        this.#state = {
            items: [],
            addresses: {},
            paymentMethods: {},
            deliveryId: null,
        };
        this.#snapshot = structuredClone(this.#state);
        this.#isInit = false;
    }

    async clear() {
        this.#state = {
            items: [],
            addresses: {},
            paymentMethods: {},
            deliveryId: null,
        };
        this.#snapshot = structuredClone(this.#state);
        this.#isInit = false;

        await this.save(true);

        eventEmitter.emit("clear", { target: this.#state });
        eventEmitter.removeAllListeners();
    }

    async load() {
        if (this.#isInit) {
            return this.#state;
        }

        const oldState = this.#state;

        this.#state = await this.#storage.load();
        this.#snapshot = structuredClone(this.#state);
        this.#isInit = true;

        eventEmitter.emit("init", { target: this.#state, old: oldState });

        return this.#state;
    }

    async save(onPayment = false) {
        const res = await this.#storage.save(this.#state, onPayment);

        if (!res.success) {
            this.#state = structuredClone(this.#snapshot);
        } else {
            this.#snapshot = structuredClone(this.#state);
        }

        return res;
    }

    async getItemIndex(productId) {
        await this.load();
        return this.#state.items.findIndex(
            (item) => item.productId === productId,
        );
    }

    async addItem(productId) {
        return await this.addMultipleItems(productId, 1);
    }

    async updateItem(productId, update) {
        await this.load();

        const index = await this.getItemIndex(productId);

        if (index === -1) {
            return;
        }

        this.#state.items[index] = update(this.#state.items[index]);

        return await this.save();
    }

    async addMultipleItems(productId, count) {
        await this.load();

        if (count < 1) {
            return;
        }

        let index = await this.getItemIndex(productId);

        if (index === -1) {
            this.#state.items.push({
                productId,
                count: 0,
            });

            index = this.#state.items.length - 1;
        }

        const old = { ...this.#state.items[index] };

        this.#state.items[index].count += count;

        const res = await this.save();

        if (res.success) {
            eventEmitter.emit("add", { target: this.#state.items[index], old });
        }

        return res;
    }

    async removeItem(productId) {
        await this.load();

        const index = await this.getItemIndex(productId);

        if (index === -1) {
            return;
        }

        const old = { ...this.#state.items[index] };
        this.#state.items[index].count--;
        eventEmitter.emit("remove", { target: this.#state.items[index], old });

        if (this.#state.items[index].count <= 0) {
            this.#state.items.splice(index, 1);
        }

        if (!this.#state?.items?.length) {
            this.#state.deliveryId = 0;
        }

        await this.save();
        eventEmitter.emit("removed", { target: productId, old });
    }

    async removeAllItemsWithId(productId) {
        await this.load();

        const index = await this.getItemIndex(productId);

        if (index === -1) {
            return;
        }

        const old = { ...this.#state.items[index] };
        this.#state.items[index].count = 0;
        eventEmitter.emit("remove", { target: this.#state.items[index], old });
        this.#state.items.splice(index, 1);

        if (!this.#state?.items?.length) {
            this.#state.deliveryId = 0;
        }

        await this.save();
        eventEmitter.emit("removed", { target: productId, old });
    }

    async setItemCount(productId, count) {
        let old = undefined;
        let current = undefined;

        const res = await this.updateItem(productId, (item) => {
            old = structuredClone(item);
            item.count = count;
            current = structuredClone(item);

            return item;
        });

        if (res.success) {
            eventEmitter.emit("set-count", { target: current, old });
        } else {
            const limit = res?.meta?.limit;

            if (limit) {
                current.count = limit;
                eventEmitter.emit("set-count", { target: current, old });
            }
        }

        return res;
    }

    async getItemDelivery(productId) {
        await this.load();
        const item = this.#state.items.find((el) => el.productId === productId);
        return item?.deliveryId ?? 0;
    }

    async setItemDelivery(productId, deliveryId) {
        await this.updateItem(productId, (item) => {
            item.deliveryId = deliveryId;
            return item;
        });
    }

    async removeItemDelivery(productId) {
        await this.load();

        const index = await this.getItemIndex(productId);

        if (index === -1) {
            return;
        }

        delete this.#state.items[index].deliveryId;

        await this.save();
    }

    async getDelivery() {
        await this.load();
        return this.#state.deliveryId;
    }

    async setDelivery(deliveryId) {
        await this.load();
        this.#state.deliveryId = deliveryId;
        await this.save();
    }

    async setDiscountCode(code) {
        await this.load();
        this.#state.discountCode = code?.length ? code : "";
        await this.save();
    }

    async getDiscountCode() {
        await this.load();
        return this.#state.discountCode;
    }

    async getItems() {
        await this.load();
        return this.#state.items;
    }

    async getCartTimer() {
        const result = await this.#storage.load();

        return result.expire;
    }

    async setDeliveryAddress(deliveryId, addressId) {
        await this.load();
        this.#state.addresses = this.#state.addresses || {};

        if (Array.isArray(this.#state.addresses)) {
            this.#state.addresses = {};
        }

        this.#state.addresses[deliveryId] = addressId;
        await this.save();
    }

    async updateDeliveryPaymentMethods(deliveryId, paymentMethods) {
        await this.load();

        if (!paymentMethods.size) {
            return;
        }

        this.#state.paymentMethods = Object.fromEntries(
            paymentMethods.entries(),
        );

        await this.save();
    }

    async removeDeliveryPaymentMethod(deliveryId, type) {
        await this.load();
        delete this.#state.paymentMethods[`${deliveryId}-${type}`];
        await this.save();
    }

    on(eventName, eventHandler) {
        eventEmitter.on(eventName, eventHandler);
    }

    once(eventName, eventHandler) {
        eventEmitter.once(eventName, eventHandler);
    }

    removeAllEventListeners() {
        eventEmitter.removeAllListeners();
    }
}

const createDbStorage = () => {
    const load = async () => {
        try {
            const response = await fetchCart();
            const data = response?.data ? {...response.data, 'expire' : response.expire} : {};

            return Object.keys(data).length ? data : null;
        } catch (error) {
            console.error(error);
        }
    };

    const save = (state, onPayment = false) => persist(JSON.stringify(state), onPayment);

    return {
        load,
        save,
    };
};

export default Api;

const api = new Api({ storageKey: "", storage: createDbStorage });

export const createApi = () => {
    return api;
};

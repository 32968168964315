/**
 * External dependencies:
 */
import _ from "lodash";
import axios from "axios";

/**
 * Internal dependencies:
 */
import route from "@/utils/route";

export const getDetails = _.debounce(
    async (delivery) => {
        const res = await axios.get(route("cart.details"), {
            params: {
                delivery,
            },
        });

        return res.data;
    },
    100,
    { leading: true, trailing: false },
);

export const persist = async (cart, onPayment = false) => {
    const res = await axios.post(route("cart.store"), {
        cart,
        onPayment
    });
    return res.data;
};

export const fetch = _.debounce(
    async () => {
        const res = await axios.get(route("cart.fetch"));
        return res.data;
    },
    100,
    { leading: true, trailing: false },
);
